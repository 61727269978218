import { clientFetch } from '../clientFetch';

export const getLatLon = () => {
  return clientFetch(`/location/ip-locate`);
};

export const getAddressDetailsFromPlaceId = ({ placeId }, signal) => {
  return clientFetch('/location/search/geoPlace', {
    params: {
      placeId,
    },
    signal,
  });
};
