import { colorLookup } from '@medifind/utils';
import { SVG } from './SVG';

const IconLocationPin = ({ size = 12, color }) => {
  const colorCode = colorLookup(color) ?? colorLookup('black');
  return (
    <SVG width={(size / 3) * 2} height={size} viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.30781 1.69593C-0.0226138 3.02636 -0.210642 5.11809 0.861188 6.66445L4.05945 11.2787L7.25776 6.66445C8.32961 5.11809 8.14159 3.02636 6.81116 1.69593C5.29145 0.176224 2.82752 0.176228 1.30781 1.69593ZM4.00163 6.00017C5.1062 6.00017 6.00163 5.10474 6.00163 4.00017C6.00163 2.8956 5.1062 2.00017 4.00163 2.00017C2.89706 2.00017 2.00163 2.8956 2.00163 4.00017C2.00163 5.10474 2.89706 6.00017 4.00163 6.00017Z"
        fill={colorCode}
      />
    </SVG>
  );
};
export { IconLocationPin as default, IconLocationPin };
