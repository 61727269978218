import { clientFetch } from '../clientFetch';

export const locationAutocomplete = ({ input, countryCodes, lat, lon, types }, signal) => {
  return clientFetch('/autocomplete/location', {
    params: {
      input,
      countryCodes,
      lat,
      lon,
      types,
    },
    signal,
  });
};
