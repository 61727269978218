import { colorLookup } from '@medifind/utils';
import { SVG } from './SVG';
const IconCross = ({ color, width = 25, height = 25, style }) => {
  const colorCode = colorLookup(color) ?? colorLookup('blue');

  return (
    <SVG width={width} height={height} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
      <path
        d="M7.25937 7.25937C7.33195 7.18661 7.41816 7.12889 7.51307 7.0895C7.60799 7.05012 7.70974 7.02985 7.8125 7.02985C7.91526 7.02985 8.01701 7.05012 8.11193 7.0895C8.20684 7.12889 8.29305 7.18661 8.36563 7.25937L12.5 11.3953L16.6344 7.25937C16.707 7.18673 16.7932 7.12911 16.8882 7.0898C16.9831 7.05049 17.0848 7.03026 17.1875 7.03026C17.2902 7.03026 17.3919 7.05049 17.4868 7.0898C17.5818 7.12911 17.668 7.18673 17.7406 7.25937C17.8133 7.332 17.8709 7.41824 17.9102 7.51314C17.9495 7.60805 17.9697 7.70977 17.9697 7.81249C17.9697 7.91522 17.9495 8.01694 17.9102 8.11184C17.8709 8.20675 17.8133 8.29298 17.7406 8.36562L13.6047 12.5L17.7406 16.6344C17.8133 16.707 17.8709 16.7932 17.9102 16.8881C17.9495 16.983 17.9697 17.0848 17.9697 17.1875C17.9697 17.2902 17.9495 17.3919 17.9102 17.4868C17.8709 17.5817 17.8133 17.668 17.7406 17.7406C17.668 17.8133 17.5818 17.8709 17.4868 17.9102C17.3919 17.9495 17.2902 17.9697 17.1875 17.9697C17.0848 17.9697 16.9831 17.9495 16.8882 17.9102C16.7932 17.8709 16.707 17.8133 16.6344 17.7406L12.5 13.6047L8.36563 17.7406C8.29299 17.8133 8.20675 17.8709 8.11185 17.9102C8.01694 17.9495 7.91522 17.9697 7.8125 17.9697C7.70978 17.9697 7.60806 17.9495 7.51315 17.9102C7.41825 17.8709 7.33201 17.8133 7.25937 17.7406C7.18674 17.668 7.12912 17.5817 7.08981 17.4868C7.0505 17.3919 7.03026 17.2902 7.03026 17.1875C7.03026 17.0848 7.0505 16.983 7.08981 16.8881C7.12912 16.7932 7.18674 16.707 7.25937 16.6344L11.3953 12.5L7.25937 8.36562C7.18662 8.29305 7.1289 8.20683 7.08951 8.11192C7.05013 8.01701 7.02985 7.91525 7.02985 7.81249C7.02985 7.70973 7.05013 7.60798 7.08951 7.51306C7.1289 7.41815 7.18662 7.33194 7.25937 7.25937Z"
        fill={colorCode}
      />
    </SVG>
  );
};

export { IconCross as default, IconCross };
